type Mode = 'prod' | 'qa' | 'dev';

export const mode: Mode = (import.meta.env.GATSBY_RUN_MODE?.trim() || 'prod') as Mode;

export default {
  SENTRY_DSN: import.meta.env.GATSBY_SENTRY_DSN,
  SENTRY_ENVIRONMENT: mode,
  CURRENT_COMMIT: import.meta.env.GATSBY_CURRENT_COMMIT,
  LOGTO_SENTRY: mode !== 'dev',
  HIDE_APP: import.meta.env.NODE_ENV !== 'test',
  APP_BUILD_NUMBER: import.meta.env.GATSBY_APP_BUILD_NUMBER,
  BASE_API_NEW: import.meta.env.GATSBY_BASE_API_NEW?.trim(),
  BASE_EXPORT_API: import.meta.env.GATSBY_BASE_EXPORT_API?.trim(),
  BASE_CONTENT_API: import.meta.env.GATSBY_BASE_CONTENT_API?.trim(),
  TWILIO_URL: import.meta.env.GATSBY_TWILIO_URL?.trim(),
  GTM_ID: import.meta.env.GATSBY_GTAG?.trim(),
  COGNITO_USER_POOL_ID: import.meta.env.GATSBY_AWS_USER_POOL_ID?.trim(),
  COGNITO_USER_POOL_WEB_CLIENT_ID: import.meta.env.GATSBY_AWS_USER_POOL_WEB_CLIENT_ID?.trim(),
  RUDDERSTACK_WRITE_KEY: import.meta.env.GATSBY_RUDDERSTACK_WRITE_KEY?.trim(),
  RUDDERSTACK_DATA_PLANE_URL: import.meta.env.GATSBY_RUDDERSTACK_DATA_PLANE_URL?.trim(),
};
